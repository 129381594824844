import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl'
import InputCode from '@/shared/ui/InputCode';
import ConfirmationEmailImage from '@/shared/assets/confirmation-email.png';
import { IconResend } from '@/shared/ui/Icons';
import { CONFIRMATION_CODE_LENGTH, MAX_CODE_SENT_TIMEOUT } from '~/Confirmation/constants/confirmation';
import { 
  getSendEmailTimeoutFromCookie,
  setEmailSentTimeToCookie,
 } from '~/Confirmation/utils/emailTimeout';
import { useSelector } from 'react-redux';
import { emailSelector } from '@/processes/Auth';
import { useSendConfirmationMutation } from '~/Confirmation/api';
import { maskEmail } from '@/shared/utils/maskEmail';
import { useInterval, useBoolean } from 'react-use';

type ConfirmationEmailProps = {
  onComplete?: (code: string) => void;
  onChange?: (code: string) => void;
  code?: any,
}

const ConfirmationEmail = (props: ConfirmationEmailProps) => {
  const {
    onComplete,
    onChange,
    code,
  } = props;
  
  const timeout = getSendEmailTimeoutFromCookie();
  const [count, setCount] = useState(timeout);
  const [delay, setDelay] = useState(1000);
  const [isRunning, toggleIsRunning] = useBoolean(!!timeout);

  const email = useSelector(emailSelector);
  const [sendConfirmation] = useSendConfirmationMutation();

  const handleResend = async () => {
    if (!isRunning) {
      setEmailSentTimeToCookie();
      toggleIsRunning(true)
      setCount(MAX_CODE_SENT_TIMEOUT);
      await sendConfirmation(null);
    }
  }

  useEffect(() => {
    handleResend();
  }, [])

  useInterval(
    () => {
      if (count === 0) {
        toggleIsRunning(false);
      } else {
        setCount(count - 1);
      }
    },
    isRunning ? delay : null
  );

  return (
    <div className="text-center">
      <img 
        src={ConfirmationEmailImage.src} 
        alt="email" 
        className='max-w-[155px] mx-auto mb-5' 
      />
      <h3 className='text-2xl text-white mb-1'>
        <FormattedMessage id="conf.email.title"/>
      </h3>
      <p className='text-xs text-white leading-[18px] mb-6'>
        <FormattedMessage 
          id="conf.email.desc"
          values={{
            br: <br/>,
            email: maskEmail(email),
            button: (chunks) => <button 
              disabled={isRunning}
              className='text-[#F67702] mt-1 inline-flex items-center disabled:text-white/[0.6]'
              onClick={handleResend}
            >
              {chunks}
              <IconResend className='ml-1'/>
              {isRunning ? (
                <span className='ml-1'>in 
                  <span className='w-[25px] inline-block text-left ml-1'>
                    {count}s
                  </span>
                </span>
              ) : null}
            </button>,
          }}
        />
      </p>
      <InputCode
        len={CONFIRMATION_CODE_LENGTH}
        onComplete={onComplete}
        onChange={onChange}
        value={code}
      />
    </div>
  );
}

export default ConfirmationEmail;
