import { useSelector } from 'react-redux';
import _get from 'lodash/get';
import { selectProfitPerPower } from '../poolStats.selectors';

type useProfitPerPowerProps = {
  coin: string;
}

export const useProfitPerPower = (props: useProfitPerPowerProps) => {
  const { coin } = props;
  const profitPerPower = useSelector((state) => selectProfitPerPower(state, coin));
  return {
    profitPerPower,
  }
}

export default useProfitPerPower;
