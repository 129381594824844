import * as React from 'react';

const IconSearch = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    fill="none"
    viewBox="0 0 16 17"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.4 13.3a5.4 5.4 0 100-10.8 5.4 5.4 0 000 10.8zm0 .667a6.067 6.067 0 100-12.134 6.067 6.067 0 000 12.134z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.43 11.931c.13-.13.342-.13.472 0l3 3a.333.333 0 11-.471.471l-3-3a.333.333 0 010-.471z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default IconSearch;
