const IconLoadingLogo = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="-115 0 89 98"
      xmlSpace="preserve"
      {...props}
    >
      <path
        fill="#878d98"
        opacity={0.3}
        d="M-97.8 13.3l-9.8 5.7c-4.1 2.3-7.4 8.1-7.4 12.8v34.4c0 4.7 3.3 10.3 7.4 12.8l9.8 5.7V13.3z"
      >
        <animate
          attributeType="CSS"
          attributeName="opacity"
          from={0.3}
          to={0.3}
          dur="0.8s"
          values="0.30; 0.30; 1; 0.30;"
          keyTimes="0; 0.3; 0.7; 1"
          repeatCount="indefinite"
          begin="0.6s"
        />
      </path>
      <path
        fill="#878d98"
        opacity={0.3}
        d="M-33.4 18.9l-9.8-5.7v71.2l9.8-5.7c4.1-2.3 7.4-8.1 7.4-12.8V31.7c0-4.7-3.3-10.4-7.4-12.8z"
      >
        <animate
          attributeType="CSS"
          attributeName="opacity"
          from={0.3}
          to={0.3}
          dur="0.8s"
          values="0.30; 0.30; 1; 0.30;"
          keyTimes="0; 0.3; 0.7; 1"
          repeatCount="indefinite"
          begin="0.2s"
        />
      </path>
      <path
        fill="#878d98"
        opacity={0.3}
        d="M-79.2 95.3l1.3.7c4.1 2.3 10.7 2.3 14.7 0l1.3-.7v-37s-2.5-4.3-8.7-4.3-8.7 4.3-8.7 4.3v37z"
      >
        <animate
          attributeType="CSS"
          attributeName="opacity"
          from={0.3}
          to={0.3}
          dur="0.8s"
          values="0.30; 0.30; 1; 0.30;"
          keyTimes="0; 0.3; 0.7; 1"
          repeatCount="indefinite"
          begin="0.4s"
        />
      </path>
      <path
        fill="#878d98"
        opacity={0.3}
        d="M-63.1 1.8c-4.1-2.3-10.7-2.3-14.7 0l-1.3.7v36.4s2.5 4.3 8.7 4.3 8.7-4.3 8.7-4.3V2.5l-1.4-.7z"
      >
        <animate
          attributeType="CSS"
          attributeName="opacity"
          from={0.3}
          to={0.3}
          dur="0.8s"
          values="0.30; 0.30; 1; 0.30;"
          keyTimes="0; 0.3; 0.7; 1"
          repeatCount="indefinite"
          begin="0s"
        />
      </path>
    </svg>
  )
}

export default IconLoadingLogo;
