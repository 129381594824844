import { useEffect } from 'react';
import { useNavigation } from '@/processes/Router';

const Themes = () => {
  const { coin } = useNavigation();

  useEffect(() => {
    const html = document.querySelector("html");
    if (html) {
      html.className = `theme-${coin}`;
    }
  }, [coin]);

  return null;
}

export default Themes;
