import '@/shared/styles/globals.scss'
import _get from 'lodash/get'
import type { AppProps } from 'next/app'
import { IntlProvider } from 'react-intl'
import { ParallaxProvider } from 'react-scroll-parallax';
import en from '@/shared/locales/en.json';
import zh from '@/shared/locales/zh.json';
import {Provider} from "react-redux";
import { wrapper } from '@/global/store';
import Auth from '@/processes/Auth';
import Intercom from '@/processes/Intercom';
import CookieBanner from '@/processes/CookieBanner';
import { PoolStats } from '@/processes/PoolStats';
import { useNavigation } from '@/processes/Router';
import Themes from '@/processes/Themes';
import Confirmation from '@/features/Confirmation';
import Toasts from "@/shared/ui/Toasts";

const messages = {
  en,
  zh,
};

function App({ Component, pageProps }: AppProps) {
  const { locale } = useNavigation();
  const currentMessages = _get(messages, locale, {});
  const {store, props} = wrapper.useWrappedStore(pageProps);
  return (
    <IntlProvider messages={currentMessages} locale={locale} defaultLocale={locale}>
      <Provider store={store}>
        <ParallaxProvider>
          <Themes/>
          <Auth>
            <Component {...props.pageProps} />
            <Intercom/>
            <CookieBanner 
              options={{
                barTrackingID: 'UA-6395920-15',
                barTrackingSite: 'pool',
              }}
            />
            <Confirmation />
            <Toasts />
            <PoolStats/>
          </Auth>
        </ParallaxProvider>
      </Provider>
    </IntlProvider>
  )
}

export default App;