const IconMinus = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="1"
      fill="none"
      viewBox="0 0 14 1"
      {...props}
    >
      <path stroke="currentColor" d="M0 0.5L14 0.5"></path>
    </svg>
  )
}

export default IconMinus;
