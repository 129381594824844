import { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl'
import Modal from '@/shared/ui/Modal';
import Button from '@/shared/ui/Button';
import Confirmation2FA from '../Confirmation2FA';
import ConfirmationEmail from '../ConfirmationEmail';
import { useDispatch, useSelector } from 'react-redux'
import {  toggleConfimation } from '~/Confirmation/Confirmation.slice';
import { TWOFA_TYPES, SECURITY_CODE_TYPES } from '~/Confirmation/constants/confirmation';
import { 
  selectShowConfirmationModal, 
  selectConfirmationType,  
  selectConfirmationSuccessMessage,
  selectConfirmationSuccessCallback,
  selectConfirmationGTMLabel,
} from '../../Confirmation.selectors';
import { useFetchLatestConfirmationQueryMutation } from '@/global/api';
import { isErrorResponse, COMMON_ERROR } from '@/global/utils/apiHelpers';
import { showSuccessToast, showErrorToast } from '@/shared/utils/notify';
import { setConfirmationCallbackState } from '@/features/Confirmation';
import { sendGtmCustomEvent } from '@/processes/CookieBanner';

type ConfirmationModalProps = {}

const ConfirmationModal = (props: ConfirmationModalProps) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const isShow = useSelector(selectShowConfirmationModal);
  const confirmationSuccessMessage = useSelector(selectConfirmationSuccessMessage);
  const confirmationSuccessCallback = useSelector(selectConfirmationSuccessCallback);
  const confirmationGtmLabel = useSelector(selectConfirmationGTMLabel);
  
  const conrimationType = useSelector(selectConfirmationType);
  const [fetchLatestConfirmationQuery, { data, isSuccess, isLoading, isError, error }] = useFetchLatestConfirmationQueryMutation();
  const is2FA = TWOFA_TYPES.includes(conrimationType);
  const isEmailVerification = SECURITY_CODE_TYPES.EMAIL === conrimationType;

  const [codeValue, setCodeValue] = useState({});
 
  const handleCodeChange = (newCode: any) => {
    setCodeValue({...newCode});
  }

  const handleOnDismiss = () => {
    sendGtmCustomEvent({
      action: 'confirm_popup_close',
      label: confirmationGtmLabel,
    });
    dispatch(toggleConfimation({ isShow: false }));
  }
  
  const handleOnCancel = () => {
    sendGtmCustomEvent({
      action: 'confirm_popup_cancel_click',
      label: confirmationGtmLabel,
    });
    dispatch(toggleConfimation({ isShow: false }));
  }

  const handleOnComplete = async (code: any) => {
    // prevent multiple sent
    if (isLoading) { return; }
    // send latest query
    const res:any = await fetchLatestConfirmationQuery({ code });
    const hasError = !!res?.error?.status;

    if (hasError) {
      setCodeValue({});
    }
  }

  useEffect(() => {
    // reset 2fa values each time when confirmation modal appears
    if (isShow) {
      setCodeValue({});
    }
  }, [isShow]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(toggleConfimation({ isShow: false }));
      showSuccessToast(intl.formatMessage({ id: confirmationSuccessMessage || 'Success' }));
      if (typeof confirmationSuccessCallback === 'function') {
        confirmationSuccessCallback(data);
        // reset callback state
        dispatch(setConfirmationCallbackState);
      }
    }
  }, [isSuccess, data]); // eslint-disable-line

  useEffect(() => {
    if (isError && isErrorResponse(error)) {
      const errorMsg = error?.data?.message || COMMON_ERROR;
      showErrorToast(intl.formatMessage({ id: errorMsg }));
    }
  }, [isError, error]); // eslint-disable-line

  useEffect(() => {
    if (confirmationGtmLabel) {
      sendGtmCustomEvent({
        action: 'confirm_popup_show',
        label: confirmationGtmLabel,
      });
    }
  }, [confirmationGtmLabel]); // eslint-disable-line

  return (
    <Modal show={isShow} onDismiss={handleOnDismiss}>
      {is2FA ? (
        <Confirmation2FA onComplete={handleOnComplete} onChange={handleCodeChange} code={codeValue} />
      ): null} 
      
      {isEmailVerification ? (
        <ConfirmationEmail onComplete={handleOnComplete} onChange={handleCodeChange} code={codeValue} />
      ) : null}
      
      <div className="flex justify-end mt-6">
        <Button 
          onClick={handleOnCancel}
          className='min-w-[88px]'
        >
          <FormattedMessage id="Cancel"/>
        </Button>
        <Button 
          onClick={() => {
            sendGtmCustomEvent({
              action: 'confirm_popup_confirm_click',
              label: confirmationGtmLabel,
            });
          }}
          disabled={isLoading}
          className='ml-5 min-w-[88px]' 
          colorSchema='primary'
        >
          <FormattedMessage id="Submit"/>
        </Button>
      </div>
    </Modal>
  );
}

export default ConfirmationModal;
