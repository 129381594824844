
import _some from 'lodash/some';
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { HYDRATE } from "next-redux-wrapper";

type AccountType = 'account' | 'address';
type RecentSearch = { coin: string, address: string };

const LIMIT_FOR_RECENT_SEARCHES = 5;

const initialState = {
  selectedAccount: null,
  type: 'account' as AccountType,
  recentSearches: [] as RecentSearch[],
}

export const confirmationSlice = createSlice({
  name: 'minerAccounts',
  initialState,
  reducers: {
    setMinerAccount: (state, { payload }) => {
      const { selectedAccount, type = 'account' } = payload;
      state.selectedAccount = selectedAccount;
      state.type = type;
    },
    addToRecentSearches: (state, { payload }: PayloadAction<RecentSearch>) => {
      // add to recent searches if not exists
      if (!_some(state.recentSearches, payload)) {
        // add element to the beginning of the list
        state.recentSearches.unshift(payload);

        // limit recent searches
        if (state.recentSearches.length > LIMIT_FOR_RECENT_SEARCHES) {
          state.recentSearches.splice(LIMIT_FOR_RECENT_SEARCHES);
        }
      }      
    },
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload.minerAccounts,
      };
    },
  }
})

export const { 
  setMinerAccount,
  addToRecentSearches,
} = confirmationSlice.actions;

export default confirmationSlice.reducer
