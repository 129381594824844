import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux';
import useCurrency from '@/shared/hooks/useCurrency';
import { selectExchangeRate } from '@/processes/PoolStats/poolStats.selectors'

type useCurrencyProps = {
  coin: string;
}
export const useCurrencyWithExchange = (props: useCurrencyProps) => {
  const { coin } = props
  const exchangeRate = useSelector((state) => selectExchangeRate(state, coin));
  const { formatCurrency: format } = useCurrency({ exchangeRate })
  return {
    formatCurrency: (value: string | number) => {
      return format(value);
    }
  }
}

export default useCurrencyWithExchange;