import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { FormattedMessage } from 'react-intl'
import s from './CookieBanner.module.scss';
import Button from "@/shared/ui/Button";
import IconCookie from "@/shared/ui/Icons/IconCookie";
import {
  GDPR_GA,
  GDPR_GTM,
  GDPR_FB,
  GDPR_HJ,
  isAllGdprNotSet,
  initializeAndTrack,
  sendAnonymusBannerStat,
} from "@/processes/CookieBanner/utils/gdpr-analytics";

const gtmBannerUpdatedAt = 'Tue Mar 28 2023 10:55:11';

const CookieBanner = (props: any) => {
  const {
    options, 
    forceShow,
    onAcceptCallback,
  } = props;
  const [showCookieBanner, setShowCookieBanner] = useState(false);

  const handleAccept = (isAccept: boolean) => {
    const expires = 3650; // 10 years
    const checked = isAccept;
    const checkedValue = String(checked);

    Cookies.set(GDPR_GA, checkedValue, {expires});
    Cookies.set(GDPR_GTM, checkedValue, {expires});
    Cookies.set(GDPR_FB, checkedValue, {expires});
    Cookies.set(GDPR_HJ, checkedValue, {expires});

    setShowCookieBanner(false);
    checked && initializeAndTrack(options);

    if (typeof onAcceptCallback === 'function') {
      onAcceptCallback(checked);
    }
    sendAnonymusBannerStat(options.barTrackingID, 'site_consent', 'popup_click', JSON.stringify({
      site: options.barTrackingSite, 
      button: isAccept ? 'agree' : 'decline', 
      banner_updated_date: gtmBannerUpdatedAt,
    }));
  };

  useEffect(() => {
    // if GDPR is not accepted, display cookie banner
    if (isAllGdprNotSet() || forceShow) {
      sendAnonymusBannerStat(options.barTrackingID, 'site_consent', 'popup_shown', JSON.stringify({
        site: options.barTrackingSite,
        banner_updated_date: gtmBannerUpdatedAt,
      }));
      setShowCookieBanner(true);

      // if GDPR is accepted, initialize GA + GTM and track data
    } else {
      initializeAndTrack(options);
    }
  }, []); // eslint-disable-line

  if (!showCookieBanner) {
    return null;
  }

  return (
    <div className={s.root}>
      {/* show intercom chat after cookie accept or decline cookie banner */}
      <style>{` 
          @media (max-width: 640px) {
            .intercom-lightweight-app { display: ${showCookieBanner ? 'none' : 'block'} }
          }
        `}
      </style>
      <div className="flex gap-2 items-center text-2xl leading-6 sm:mb-4 mb-2">
        <IconCookie /> <FormattedMessage id="cookieBanner.title" />
      </div>
      
      <p className="text-xs leading-4">
        <FormattedMessage
          id="cookieBanner.desc"
          values={{
            a: (chunks) => <a
              target={'_blank'}
              href="https://hiveon.com/privacy/"
              className={s.link}
            >
              {chunks}
            </a>
          }}
        />
      </p>

      <div className="flex justify-end sm:mt-6 mt-4">
        <Button
          onClick={() => handleAccept(false)}
          className='min-w-[88px]'
        >
          <FormattedMessage id="cookieBanner.decline"/>
        </Button>
        <Button
          onClick={() => handleAccept(true)}
          className='ml-5 min-w-[88px]'
          colorSchema='primary'
        >
          <FormattedMessage id="cookieBanner.agree"/>
        </Button>
      </div>

    </div>
  );
}

export default CookieBanner;
