const IconArrowTo = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      fill="none"
      viewBox="0 0 10 10"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M3 .5a.5.5 0 000 1h4.793l-.147.146-7 7a.5.5 0 10.708.708l7-7 .146-.147V7a.5.5 0 001 0V2A1.5 1.5 0 008 .5H3z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default IconArrowTo
