import { useSelector } from 'react-redux';
import { selectCoinsStats } from '../poolStats.selectors';


export const useCoinsStats = () => {
  const coinsStats = useSelector((state) => selectCoinsStats(state));
  return {
    coinsStats,
  }
}

export default useCoinsStats;
