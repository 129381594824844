// routes which require login
export const PROTECTED_ROUTES = [
  '/btc/dashboard',
  '/kas/dashboard',
  '/dnx/dashboard',
  '/gram/dashboard',
]

export const isProtectedRoute = () => {
  let protectedRoute = '';
  try {
    PROTECTED_ROUTES.forEach((route: string) => {
      if(
        window.location.pathname.includes(route)
        && !window.location.search.includes('miner')
        && !window.location.search.includes('token')
      ) {
        protectedRoute = route;
      }
    });
  } catch (e) {}
  return protectedRoute;
}
