
import {
  createSlice
} from "@reduxjs/toolkit";
import { DAY } from './constants/settings';
import { HYDRATE } from "next-redux-wrapper";

const initialState = {
  isLiveReload: false,
  dateRange: DAY,
}

export const confirmationSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    toggleLiveReload: (state, { payload }) => {
      state.isLiveReload = payload;
    },
    setDateRange: (state, { payload }) => {
      state.dateRange = payload;
    },
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload.settings,
      };
    },
  }
})

export const { 
  toggleLiveReload,
  setDateRange,
} = confirmationSlice.actions;

export default confirmationSlice.reducer
