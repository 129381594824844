import * as React from 'react';

const IconFilledInfo = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    fill="none"
    viewBox="0 0 14 14"
    {...props}
  >
    <path
      fill="currentColor"
      d="M7 .334A6.665 6.665 0 00.332 7a6.665 6.665 0 006.666 6.666A6.665 6.665 0 0013.666 7 6.665 6.665 0 006.998.334zm0 10a.667.667 0 01-.667-.668V7a.667.667 0 011.333 0v2.666a.667.667 0 01-.667.668zM7.332 5h-.667a.333.333 0 01-.333-.334V4c0-.184.149-.334.333-.334h.667c.184 0 .333.15.333.334v.666A.333.333 0 017.333 5z"
    ></path>
  </svg>
);

export default IconFilledInfo;
