import { getUserinfo } from './api';
import { createSelector } from '@reduxjs/toolkit';
import _get from 'lodash/get';

export const userSelectorRaw = (state: any) => { 
  return getUserinfo.select(null)(state) || {}; 
}

export const userSelector = (state: any) => { return userSelectorRaw(state)?.data ?? {}; }
export const selectUsername = (state: any) => { return userSelector(state)?.username || ''; }
export const emailSelector = (state: any) => { return userSelector(state)?.email || ''; }

export const selectIsAuth = (state: any) => { 
  const isUsername = Boolean(selectUsername(state));
  return isUsername;
}
