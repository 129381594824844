import React from 'react';
import { NextSeo, FAQPageJsonLd } from 'next-seo';
import _get from 'lodash/get';
import { useIntl } from 'react-intl';
import { useNavigation } from '../hooks/useNavigation'
import { meta } from '../constants/meta'
import { BASE_HOST, IS_STAGE_BUILD } from '@/shared/constants/env';

type SEOProps = {
  metaKey?: string;
  noIndex?: boolean;
}

export default function SEO(props: SEOProps) {
  const {
    metaKey,
    noIndex = false,
  } = props;

  const intl = useIntl();
  const { coin, coinTitle, router, locale } = useNavigation();
  const { defaultLocale, locales = [] } = router;
  const pageMeta = _get(meta, metaKey || coin, {});
  const titleKey = _get(pageMeta, 'title', '');
  const descriptionKey = _get(pageMeta, 'desc', '');
  const title = intl.formatMessage({ id: titleKey }, { coinTitle, coin });
  const description = intl.formatMessage({ id: descriptionKey }, { coinTitle, coin });;
  const preview = _get(pageMeta, 'preview', null);

  const additionalMetaTags = [
    { name: 'viewport', content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no' },
    { name: 'apple-mobile-web-app-title', content: 'Hiveon Pool' },
    { name: 'application-name', content: 'Hiveon Pool' },
    { name: 'msapplication-TileColor', content: '#da532c' },
    { name: 'theme-color', content: '#ffffff' },
    { name: 'theme-color', content: '#ffffff' },
  ];
  if (noIndex || IS_STAGE_BUILD) {
    additionalMetaTags.push({ name: 'robots', content: 'noindex,nofollow' })
  }
  if (preview) {
    additionalMetaTags.push({ name: 'twitter:image', content: `${preview}` })
  }

  // Helper function to remove trailing slash for specified pages
  const removeTrailingSlashFromSpecificPages = (url: string) => {
    const pagesToRemoveTrailingSlash = [`${BASE_HOST}/`,`${BASE_HOST}/zh/`];

    if (pagesToRemoveTrailingSlash.includes(url)) {
      return url.replace(/\/$/, "");
    }

    return url;
  }

  // adding hraflang
  const languageAlternates = locales.map((locale) => {
    let url = `${BASE_HOST}${locale === defaultLocale ? '' : `/${locale}`}${router.asPath}`;
    url = removeTrailingSlashFromSpecificPages(url);

    return {
      hrefLang: locale,
      href: url,
    }
  });

// adding canonical
  let canonical = `${BASE_HOST}${locale === defaultLocale ? '' : `/${locale}`}${router.asPath}`;
  canonical = removeTrailingSlashFromSpecificPages(canonical);

  return (
    <>
      <NextSeo
        title={title}
        description={description}
        additionalMetaTags={additionalMetaTags}
        languageAlternates={languageAlternates}
        canonical={canonical}
        openGraph={{
          images: [
            {
              url: preview,
              width: 1200,
              height: 630,
            },
          ],
        }}
      />
    </>
  )
}
