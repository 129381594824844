import { useSelector } from 'react-redux';
import { selectCryptoCurrencies } from '../poolStats.selectors';


export const useCryptoCurrencies = () => {
  const cryptoCurrencies = useSelector((state) => selectCryptoCurrencies(state));

  return {
    cryptoCurrencies,
  }
}

export default useCryptoCurrencies;
