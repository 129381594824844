const IconList = (props: any) => (
  <svg
    width={12}
    height={10}
    viewBox="0 0 12 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 1C0 .705.239.467.533.467h.934a.533.533 0 010 1.066H.533A.533.533 0 010 1zM3.333 1c0-.295.24-.533.534-.533H10.8a.533.533 0 110 1.066H3.867A.533.533 0 013.333 1zM0 5c0-.295.239-.533.533-.533h.934a.533.533 0 010 1.066H.533A.533.533 0 010 5zM3.333 5c0-.295.24-.533.534-.533H10.8a.533.533 0 110 1.066H3.867A.533.533 0 013.333 5zM0 9c0-.295.239-.533.533-.533h.934a.533.533 0 010 1.066H.533A.533.533 0 010 9zM3.333 9c0-.295.24-.533.534-.533H10.8a.533.533 0 110 1.066H3.867A.533.533 0 013.333 9z"
      fill="currentColor"
    />
  </svg>
);

export default IconList;
