const IconPending = (props: any) => {
  return (
    <svg
      width={20}
      height={22}
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.546 1.5a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1zM18.16 6.317l-2.95-2.5 1.294-1.525 2.95 2.5-1.294 1.526z"
        fill="currentColor"
      />
      <path
        d="M9.546 3.5c-4.961 0-9 4.04-9 9s4.039 9 9 9c4.96 0 9-4.04 9-9s-4.04-9-9-9zm1 10h-2v-7h2v7z"
        fill="currentColor"
      />
    </svg>
  )
}

export default IconPending;
