import { useGetUserinfoQuery } from './api';
import { useNavigation } from '@/processes/Router';
import TextLoader from '@/shared/ui/TextLoader';

type AuthProps = {
  children: React.ReactNode;
}

const Auth = (props: AuthProps) => {
  const { children } = props;
  const { isProtectedRoute } = useNavigation();
  // we need to refetch data from api, cause cookie jwt doesn't have intercom data
  const {isSuccess} = useGetUserinfoQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  if (isProtectedRoute && !isSuccess) { return <TextLoader width='100%' height='10px'/>; }

  return <>{children}</>;
}

export default Auth;
