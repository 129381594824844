const IconPlus = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
      {...props}
    >
      <path stroke="currentColor" d="M0 6.357L12 6.357"></path>
      <path stroke="currentColor" d="M5.643 0L5.643 12"></path>
    </svg>
  )
}

export default IconPlus;
