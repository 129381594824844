import { useSelector } from 'react-redux';
import _get from 'lodash/get';
import { selectSelectedAccount, selectRecentSearches } from '../MinerAccounts.selectors';
import { useNavigation, POOLS } from '@/processes/Router';
import { selectUsername, selectIsAuth } from '@/processes/Auth';
import { cleanAccount } from "../utils/cleanAccount";

type useMinerAccountProps = {}

export const useMinerAccount = (props: useMinerAccountProps = {}) => {
  const { query, coin, exactCoinMatch } = useNavigation();
  const recentSearches = useSelector(selectRecentSearches);
  const mainAccount = useSelector(selectUsername);
  const isAuth = useSelector(selectIsAuth);
  const { selectedAccount } = useSelector(selectSelectedAccount);
  const isSupport = _get(query, 'support', false) === 'true';
  const accountFromQuery = _get(query, 'account', null);
  const minerAddressQuery = _get(query, 'miner', null);

  return {
    isAuth,
    coin,
    exactCoinMatch,
    isSupport: isSupport || null,
    account: selectedAccount || accountFromQuery || mainAccount,
    miner: cleanAccount(minerAddressQuery, coin),
    isAccounting: _get(POOLS, `${coin}.isAccounting`, false),
    isMinerAddressValid: (address: string) => {
      const regexpStr = _get(POOLS, `${exactCoinMatch}.regexp`, '');
      if (!regexpStr) { 
        return true 
      } else {
        const regexp = new RegExp(regexpStr);
        return regexp.test(address);
      }
    },
    findValidAddress: (address: string) => {
      let coin = '';
      Object.entries(POOLS).forEach(([key, options]: any) => {
        const regexpStr = options.regexp;
        const isAccounting = options.isAccounting;
        const isAnonymus = _get(options, 'isAnonymus', false);

        if (!regexpStr && address && (!isAccounting || isAnonymus)) {
          coin = key; 
        } else {
          const regexp = new RegExp(regexpStr);
          if (address && regexp.test(address) && (!isAccounting || isAnonymus)) {
            coin = key;
          }
        }
      });
      return {
        isValid: !!coin,
        coin: coin,
      }
    },
    recentSearches,
  }
}

export default useMinerAccount;
