export const DAY = 'd';
export const WEEK = 'w';
export const MONTH = 'm';

export const LIVE_RELOAD_INTERVAL = 60; // 1 minute timeout

export const SHARED_TIME_RANGES = {
  [DAY]: {
    window: '10m',
    limit: 144,
    offset: 0,
  },
  [WEEK]: {
    window: '6h',
    limit: 28,
    offset: 0,
  },
  [MONTH]: {
    window: '6h',
    limit: 120,
    offset: 0,
  },
}

export const SHA256_TIME_RANGES = {
  [DAY]: {
    window: '1h',
    limit: 24,
    offset: 0,
  },
  [WEEK]: {
    window: '6h',
    limit: 28,
    offset: 0,
  },
  [MONTH]: {
    window: '6h',
    limit: 120,
    offset: 0,
  },
}