const IconArrowDown = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="8"
      fill="none"
      viewBox="0 0 14 8"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        d="M13 1L7.707 6.293a1 1 0 01-1.414 0L1 1"
      ></path>
    </svg>
  )
}

export default IconArrowDown
