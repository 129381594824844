import _get from 'lodash/get';
import { toFixed } from '@/shared/utils/toFixed';

type useCryptoFormatterProps = {
  coin: string;
}

const COIN_DECIMALS = {
  BTC: 8,
  RVN: 2,
  KAS: 2,
  DNX: 2,
  ETC: 4,
  GRAM: 5,
}

export const useCryptoFormatter = (props: useCryptoFormatterProps) => {
  const { coin } = props;
  const decimals = _get(COIN_DECIMALS, coin, 5);
  return {
    formatCrypto: (value: string | number) => {
      const amount = toFixed(value, decimals);
      return `${amount} ${coin}`;
    },
    formatCryptoAmount: (value: string | number) => {
      return toFixed(value, decimals);
    },
    addCoinPostfix: (value: string | number) => {
      return `${value} ${coin}`;
    }
  }
}

export default useCryptoFormatter;