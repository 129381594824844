const IconGlobe = (props: any) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={8} cy={8} r={7.5} stroke="currentColor" />
    <path
      d="M10.5 8c0 2.165-.33 4.103-.847 5.481-.259.691-.556 1.216-.86 1.559-.304.343-.573.46-.793.46-.22 0-.49-.117-.794-.46-.304-.343-.6-.868-.86-1.559C5.83 12.103 5.5 10.165 5.5 8s.33-4.103.847-5.481c.259-.691.555-1.216.86-1.559C7.51.617 7.78.5 8 .5c.22 0 .49.117.794.46.303.343.6.868.86 1.559.516 1.378.846 3.316.846 5.481z"
      stroke="currentColor"
    />
    <path stroke="currentColor" d="M0 7.5L15 7.5" />
  </svg>
);

export default IconGlobe;
