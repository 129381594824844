import { useSelector } from 'react-redux';
import { selectCryptoCurrency } from '../poolStats.selectors';

type useCryptoCurrencyProps = {
  coin: string;
}

export const useCryptoCurrency = (props: useCryptoCurrencyProps) => {
  const { coin } = props;
  const cryptoCurrency = useSelector((state) => selectCryptoCurrency(state, coin));
  return cryptoCurrency;
}

export default useCryptoCurrency;
