import { useSelector } from 'react-redux';
import _get from 'lodash/get';
import { selectPoolStatsByCoin } from '../poolStats.selectors';

type usePoolStatsProps = {
  coin: string;
}

export const usePoolStats = (props: usePoolStatsProps) => {
  const { coin } = props;
  return useSelector((state) => selectPoolStatsByCoin(state, coin));
}

export default usePoolStats;
