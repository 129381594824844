const IconConvert = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="18"
      fill="none"
      viewBox="0 0 20 18"
      {...props}
    >
      <path
        fill="currentColor"
        fillOpacity="0.3"
        d="M20 12.14c0-.68-.52-1.225-1.152-1.225H1.174c-.401 0-.78.23-.988.593a1.308 1.308 0 00-.134.932c.074.316.26.584.528.758l.015.016L4.86 17.81c.133.119.304.19.49.19h.008c.2 0 .386-.087.527-.237a.827.827 0 00.216-.569l-.156-3.83h12.902c.632 0 1.152-.553 1.152-1.225zM0 5.86c0 .332.119.64.342.87.215.228.505.363.817.363h17.667c.401 0 .78-.23.988-.593.156-.284.208-.608.134-.932a1.231 1.231 0 00-.528-.758l-.015-.016L15.288.19a.737.737 0 00-.49-.19c-.201 0-.387.087-.528.237a.827.827 0 00-.216.569l.008 3.83H1.152C.52 4.628 0 5.181 0 5.86z"
      ></path>
    </svg>
  )
}

export default IconConvert
