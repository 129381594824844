
export const CONFIRMATION_CODE_LENGTH = 6;

export const SECURITY_CODE_TYPES = {
  TWOFA: 'twofa',
  // TOTP is synonym keycloak value for TWOFA
  TOTP: 'totp',
  EMAIL: 'email',
};

export const CODE_SENT_TIME_KEY = 'code-sent-time';
export const MAX_CODE_SENT_TIMEOUT = 60;
export const COOKIE_EXP = 1 / 24 / MAX_CODE_SENT_TIMEOUT;

export const TWOFA_TYPES = [
  SECURITY_CODE_TYPES.TWOFA, 
  SECURITY_CODE_TYPES.TOTP,
];
