const IconSuccess = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        fill="currentColor"
        d="M10 .001c-5.523 0-9.999 4.476-9.999 10 0 5.523 4.476 9.999 10 9.999C15.523 20 20 15.524 20 10 20 4.477 15.524.001 10 .001zm5.708 7.706l-7.001 7a.997.997 0 01-1.414 0l-3-3a1 1 0 011.414-1.414L8 12.586l6.294-6.293a1 1 0 011.414 1.414z"
      ></path>
    </svg>
  )
}

export default IconSuccess;
