import { useSelector } from 'react-redux';
import _get from 'lodash/get';
import { selectExplorer } from '../poolStats.selectors';

type useExplorerProps = {
  coin: string;
}

export const useExplorer = (props: useExplorerProps) => {
  const { coin } = props;
  const explorer = useSelector((state) => selectExplorer(state, coin));
  return {
    explorerLink: (hash: string) => {
      return `${explorer}${hash}`;
    },
  }
}

export default useExplorer;
