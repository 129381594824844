const IconArrowDown = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="8"
      fill="none"
      viewBox="0 0 14 8"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        d="M1 7l5.293-5.293a1 1 0 011.414 0L13 7"
      ></path>
    </svg>
  )
}

export default IconArrowDown
