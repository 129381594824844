import { useSelector } from 'react-redux';
import _get from 'lodash/get';
import { selectCoins, selectIsCoinsLoaded } from '../poolStats.selectors';

export const useCoins = () => {
  // sort coins and exclude which not mentioned in COIN_SEQUENCE
  const isCoinsLoaded = useSelector(selectIsCoinsLoaded);
  const coins = useSelector(selectCoins);

  return {
    isCoinsLoaded,
    coins,
  }
}

export default useCoins;
