import _get from 'lodash/get';
import { COIN_ROUTES } from '@/processes/Router';
import { parseJwtToken } from '@/shared/utils/jwt';
import { extendedApi } from '@/processes/Auth';

/**
 * This prevent page flickering and detech if user is authorized, based on cookie value
 * Flow:
 * 1. extract kc-access cookie value and parse JWT
 * 2. dispatch action to insert cache value for getUserinfo
 * 3. on front end, refetch getUserinfo, to retrieve the latest data from the API
 */
export const withUserDataFromCookie = (store: any): any => async (context: any) => {
  const kcAccess = context.req.cookies['kc-access'];
  if (kcAccess) {
    const token = parseJwtToken(kcAccess);
    await store.dispatch(extendedApi.util.upsertQueryData('getUserinfo', null, {
      firstName: token?.name || '',
      email: token?.email || '',
      username: token?.preferred_username || '',
      id: token?.sub,
    }));
  }

  // check if page exists, if not, redirect to 404
  const pool = context.query?.pool;
  // if pool is undefined, it means that we're on homepage
  const isPageExists = pool ? Boolean(_get(COIN_ROUTES, pool)) : true;
  if (!isPageExists) {
    return {
      notFound: true,
    }
  }
  
  return { props: {} }
};