const IconSortingArrowUp = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6"
      height="3"
      fill="none"
      viewBox="0 0 6 3"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M5.554 2.854a.5.5 0 000-.708L3.907.5a1 1 0 00-1.414 0L.847 2.146a.5.5 0 10.707.708L3.2 1.207l1.647 1.647a.5.5 0 00.707 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default IconSortingArrowUp
