import Cookies from 'js-cookie';
import {
  CODE_SENT_TIME_KEY,
  MAX_CODE_SENT_TIMEOUT,
  COOKIE_EXP,
} from '~/Confirmation/constants/confirmation';

export function getSendEmailTimeoutFromCookie(): number {
  const cookieValue = Cookies.get(CODE_SENT_TIME_KEY);
  const sentTime = Number(cookieValue) || 0;
  let remaining = 0;

  if (sentTime < Date.now() - MAX_CODE_SENT_TIMEOUT * 1000) {
    Cookies.remove(CODE_SENT_TIME_KEY);
  } else {
    remaining = (sentTime + MAX_CODE_SENT_TIMEOUT * 1000 - Date.now()) / 1000;
  }
  return parseInt(String(remaining));
}

export function setEmailSentTimeToCookie() {
  Cookies.set(CODE_SENT_TIME_KEY, String(Date.now()), { expires: COOKIE_EXP });
}
