import { useSelector } from 'react-redux';
import _get from 'lodash/get';
import { selectThreshold } from '../poolStats.selectors';

type useThresholdProps = {
  coin: string;
}

export const useThreshold = (props: useThresholdProps) => {
  const { coin } = props;
  const threshold = useSelector((state) => selectThreshold(state, coin));
  return {
    threshold,
  }
}

export default useThreshold;
