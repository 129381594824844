const IconDynexHexagonNumber3 = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="31"
    fill="none"
    viewBox="0 0 28 31"
    {...props}
  >
    <g filter="url(#filter0_d_969_20268)">
      <path
        fill="url(#paint0_linear_969_20268)"
        d="M7.219 20.46c-.75-.437-1.196-.882-1.196-2.1v-6.04c0-1.187.446-1.625 1.149-2.03l5.32-3.071c1-.578 1.985-.594 3.016 0l5.32 3.07c.703.406 1.149.844 1.149 2.031v6.04c0 1.218-.438 1.663-1.196 2.1l-5.304 3.056c-1 .578-1.97.57-2.946 0L7.22 20.46zm.828-1.335l5.133 2.969c.57.32 1.062.328 1.648 0l5.125-2.969c.383-.219.508-.422.508-.797v-5.976c0-.344-.125-.532-.461-.727l-5.14-2.984c-.602-.344-1.126-.329-1.72 0l-5.132 2.984c-.344.195-.469.383-.469.727v5.976c0 .375.133.578.508.797z"
      ></path>
      <path
        fill="url(#paint1_linear_969_20268)"
        d="M13.976 19.122c-1.548 0-2.554-.83-2.652-2.012l-.005-.058h1.206l.005.053c.069.572.616.987 1.44.987.816 0 1.358-.45 1.358-1.09v-.009c0-.742-.547-1.152-1.445-1.152h-.835v-.938h.806c.776 0 1.294-.44 1.294-1.054v-.01c0-.635-.43-1.006-1.187-1.006-.742 0-1.25.386-1.313.991l-.005.049H11.47l.005-.058c.102-1.192 1.064-1.983 2.485-1.983 1.455 0 2.397.747 2.397 1.84v.01c0 .875-.634 1.456-1.48 1.631v.025c1.04.098 1.73.713 1.73 1.69v.01c0 1.225-1.075 2.084-2.632 2.084z"
      ></path>
    </g>
    <defs>
      <filter
        id="filter0_d_969_20268"
        width="27.953"
        height="29.469"
        x="0.023"
        y="0.625"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset></feOffset>
        <feGaussianBlur stdDeviation="3"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0.192157 0 0 0 0 0.913725 0 0 0 0 0.901961 0 0 0 0.6 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_969_20268"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_969_20268"
          result="shape"
        ></feBlend>
      </filter>
      <linearGradient
        id="paint0_linear_969_20268"
        x1="14"
        x2="14"
        y1="3"
        y2="27"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#31E9E6"></stop>
        <stop offset="1" stopColor="#0873FB"></stop>
      </linearGradient>
      <linearGradient
        id="paint1_linear_969_20268"
        x1="14"
        x2="14"
        y1="3"
        y2="27"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#31E9E6"></stop>
        <stop offset="1" stopColor="#0873FB"></stop>
      </linearGradient>
    </defs>
  </svg>
);

export default IconDynexHexagonNumber3;
