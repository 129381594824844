const IconDynexHexagon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="19"
    fill="none"
    viewBox="0 0 18 19"
    {...props}
  >
    <path
      fill="url(#paint0_linear_968_18713)"
      d="M2.102 14.672c-.805-.461-1.329-.977-1.329-2.344v-5.96c0-1.345.524-1.852 1.305-2.298l5.258-3.03c1.094-.634 2.187-.657 3.32 0l5.258 3.03c.774.446 1.305.953 1.305 2.297v5.961c0 1.367-.524 1.883-1.336 2.344l-5.25 3.023c-1.11.633-2.188.625-3.281 0l-5.25-3.023zm1.062-1.735l5.055 2.93c.523.29 1.023.29 1.547 0l5.062-2.93c.297-.171.414-.351.414-.64V6.398c0-.265-.117-.437-.39-.593l-5.07-2.938c-.532-.304-1.055-.297-1.579 0L3.141 5.805c-.274.156-.399.328-.399.593v5.899c0 .289.125.469.422.64z"
    ></path>
    <defs>
      <linearGradient
        id="paint0_linear_968_18713"
        x1="9"
        x2="9"
        y1="4"
        y2="15"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#31E9E6"></stop>
        <stop offset="1" stopColor="#0873FB"></stop>
      </linearGradient>
    </defs>
  </svg>
);

export default IconDynexHexagon;
