const IconSettings = (props: any) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 11.714c0 1.275 1.062 2.318 2.36 2.318 1.299 0 2.371-1.043 2.371-2.318 0-1.275-1.072-2.318-2.37-2.318C1.062 9.396 0 10.439 0 11.714zm1.21 0c0-.637.511-1.13 1.16-1.13.65 0 1.151.493 1.151 1.13 0 .638-.501 1.13-1.15 1.13-.65 0-1.161-.492-1.161-1.13zM2.37.5a.786.786 0 00-.796.782v8.896h1.583V1.282A.778.778 0 002.37.5zm0 17c.443 0 .787-.348.787-.744v-3.361H1.574v3.361c0 .396.354.744.796.744zM6.63 6.131C6.63 7.406 7.702 8.45 9 8.45s2.36-1.043 2.36-2.318c0-1.275-1.062-2.318-2.36-2.318-1.298 0-2.37 1.043-2.37 2.318zm1.22 0c0-.628.51-1.13 1.15-1.13.65 0 1.15.502 1.15 1.13 0 .638-.5 1.13-1.15 1.13-.64 0-1.15-.492-1.15-1.13zM9 .5c-.443 0-.787.348-.787.744V4.47h1.584V1.244C9.797.848 9.443.5 9 .5zm0 17a.786.786 0 00.797-.782V7.667H8.213v9.05c0 .435.344.783.787.783zm4.279-5.786c0 1.275 1.062 2.318 2.36 2.318 1.299 0 2.361-1.043 2.361-2.318 0-1.275-1.062-2.318-2.36-2.318-1.299 0-2.361 1.043-2.361 2.318zm1.21 0c0-.637.511-1.13 1.15-1.13.66 0 1.151.493 1.151 1.13 0 .638-.492 1.13-1.15 1.13a1.13 1.13 0 01-1.151-1.13zM15.639.5a.786.786 0 00-.796.782v8.945h1.583V1.282A.778.778 0 0015.64.5zm0 17c.443 0 .787-.348.787-.744V13.26h-1.583v3.496c0 .396.354.744.796.744z"
      fill="#fff"
    />
  </svg>
);

export default IconSettings;
