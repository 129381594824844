const IconDynexInfinityWinds = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="url(#paint0_linear_968_18731)"
      d="M4.79 6.969L3.522 6.96C1.82 6.953.445 5.609.445 3.906A3.086 3.086 0 013.523.82C5.22.82 6.602 2.22 6.602 3.906v1.25h2.796v-1.25c0-1.687 1.383-3.086 3.07-3.086 1.712 0 3.087 1.39 3.087 3.086 0 1.703-1.383 3.047-3.078 3.055l-1.274.008v2.804h1.274c1.695.016 3.078 1.352 3.078 3.063a3.08 3.08 0 01-3.086 3.078c-1.688 0-3.07-1.39-3.07-3.078v-1.25H6.601v1.25a3.09 3.09 0 01-3.079 3.078 3.08 3.08 0 01-3.078-3.078c0-1.711 1.375-3.047 3.078-3.063H4.79V6.97zM3.577 5.172h1.203V3.96c0-.734-.57-1.305-1.258-1.305-.687 0-1.25.563-1.25 1.258 0 .695.57 1.258 1.305 1.258zm8.844 0c.726 0 1.305-.563 1.305-1.258 0-.695-.563-1.258-1.258-1.258-.68 0-1.258.57-1.258 1.305v1.21h1.21zm-5.82 4.61H9.39V6.96H6.6v2.82zm-3.024 1.78c-.734 0-1.305.563-1.305 1.258 0 .696.563 1.258 1.25 1.258.688 0 1.258-.57 1.258-1.305v-1.21H3.578zm8.844 0H11.21v1.211c0 .735.578 1.305 1.258 1.305.695 0 1.258-.562 1.258-1.258 0-.695-.579-1.258-1.305-1.258z"
    ></path>
    <defs>
      <linearGradient
        id="paint0_linear_968_18731"
        x1="8"
        x2="8"
        y1="3"
        y2="14"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#31E9E6"></stop>
        <stop offset="1" stopColor="#0873FB"></stop>
      </linearGradient>
    </defs>
  </svg>
);

export default IconDynexInfinityWinds;
