const IconAnalytics = (props: any) => (
  <svg
    width={15}
    height={13}
    viewBox="0 0 15 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.666.5a.5.5 0 10-1 0v8.667a.5.5 0 001 0V.5zM8.333 3.167a.5.5 0 10-1 0v6a.5.5 0 001 0v-6zm-7.167 8.166a.5.5 0 000 1h13.333a.5.5 0 100-1H1.166zm3.333-6a.5.5 0 01.5.5v3.334a.5.5 0 01-1 0V5.833a.5.5 0 01.5-.5z"
      fill="currentColor"
    />
  </svg>
);

export default IconAnalytics;
