import queryString from 'query-string';
import { useMinerAccount } from './useMinerAccount';
import { BUSINESS_PORTAL_URL } from '@/shared/constants/env';
import {toLower} from "lodash";

export const useBusinessPortalLink = () => {
  const { account, isSupport, coin } = useMinerAccount();
  return {
    getBusinessPortalLink: (params: any = {}) => {
      const { action = null, ...restParams } = params;
      const query = queryString.stringify(Object.assign(
        {
          source: `pool_${toLower(coin)}`,
          action,
        },
        restParams,
      ), { skipNull: true });
      const supportPath = isSupport ? `/support` : '';
      return `${BUSINESS_PORTAL_URL}/${account}${supportPath}?${query}`;
    },
  }
}
