import { useIntl } from 'react-intl'

type useCurrencyProps = {
  exchangeRate?: number;
}

export const useCurrency = (props: useCurrencyProps = {}) => {
  const { exchangeRate = 1 } = props;
  const currency = 'USD';
  const intl = useIntl();

  return {
    formatCurrency: (value: string | number) => {
      // if exchange rate is not valid (due to the api error), return ~
      if (typeof exchangeRate !== 'number' || !exchangeRate) {
        return '~';
      }

      const amount = Number(value) * exchangeRate;
      return intl.formatNumber(amount, { style: 'currency', currency, signDisplay: 'never' });
    }
  }
}

export default useCurrency;