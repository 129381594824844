const IconSupport = (props: any) => (
  <svg
    width={33}
    height={35}
    viewBox="0 0 33 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M32.017 19.833l-3.091 1.081a.357.357 0 00-.238.327v3.879a4.225 4.225 0 01-.6 2.173 4.302 4.302 0 01-1.636 1.57c-1.198.65-2.545 1-4.003 1.361a2.6 2.6 0 00-1.415.91 2.54 2.54 0 00-.549 1.575v2.176H6.858v-5.543c0-.657-.139-1.306-.408-1.906a4.705 4.705 0 00-1.157-1.58 15.874 15.874 0 01-3.814-5.108A15.682 15.682 0 010 14.573C.231 7.65 5.532 1.194 12.372.134v10.589a5.493 5.493 0 00-2.15 1.075 5.4 5.4 0 00-1.473 1.882 5.328 5.328 0 00.048 4.638c.357.721.873 1.354 1.51 1.853.637.5 1.38.852 2.172 1.032a5.54 5.54 0 002.411.01 5.499 5.499 0 002.18-1.015l3.53 3.48c.054.42.237.813.524 1.127a2.13 2.13 0 002.337.539c.4-.156.74-.429.978-.781a2.054 2.054 0 00-.052-2.367 2.101 2.101 0 00-1.01-.74 2.13 2.13 0 00-1.257-.038l-3.389-3.344a5.329 5.329 0 00-.122-4.504 5.41 5.41 0 00-1.457-1.803 5.499 5.499 0 00-2.087-1.038V0c7.222.353 13.04 5.93 13.584 12.949.02.233.115.454.27.631l3.65 4.217a1.272 1.272 0 01.267 1.161c-.055.2-.158.385-.3.537-.143.153-.321.269-.52.338zm-20.973-3.879c0 .521.157 1.03.45 1.463.293.433.71.77 1.198.97a2.703 2.703 0 002.909-.57c.373-.37.627-.838.73-1.349.103-.51.05-1.04-.152-1.521a2.64 2.64 0 00-.983-1.182 2.695 2.695 0 00-1.483-.443 2.703 2.703 0 00-1.89.767 2.6 2.6 0 00-.785 1.862l.006.003z"
      fill="#fff"
    />
  </svg>
);

export default IconSupport;
