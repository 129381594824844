import { useEffect } from 'react';
import { INTERCOM_APP_ID } from '@/shared/constants/env';
import { userSelector } from '@/processes/Auth';
import { useSelector } from 'react-redux';

declare global {
  interface Window {
    Intercom:any;
  }
}

const Intercom = () => {
  const { username, intercom_id } = useSelector(userSelector);

  useEffect(() => {
    // init intercom
    if (
      typeof window !== 'undefined' 
      && window.Intercom
    ) {
      const intercomOptions = {
        app_id: INTERCOM_APP_ID,
      };
      if (username && intercom_id) {
        Object.assign(intercomOptions, {
          user_id: username,
          user_hash: intercom_id,
        });
      }
      // wait for intercom_id for auth user
      if (username && !intercom_id) { return; }
      window.Intercom('boot', intercomOptions);
    }
  }, [username, intercom_id]);

  return null;
}

export default Intercom;
