import _get from 'lodash/get';
import { SHA256_TIME_RANGES, SHARED_TIME_RANGES } from '../constants/settings';

// Logic described here HIP-945
// For Hashrate block:
// - for BTC, leave the window in 1h
// - for other coins, change it to 10min. Like, 10:00, 10:10, 10:20 etc
// - make sure we display the last point with updated data (e.g. if now 15:25, the last time point is 15:00 (for BTC) and 15:20 (for other coins)
// For Workers and Shares block:
// - for all coins change it to 10min
export const getTimeRange = (coin: string, dateRange: string) => {
  const isBTC = coin === 'BTC';
  return _get(isBTC ? SHA256_TIME_RANGES : SHARED_TIME_RANGES, dateRange);
}
