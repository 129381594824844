import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { ConfirmationSlice } from '@/features/Confirmation';
import { SettingsSlice } from '@/processes/Settings';
import { MinerAccountsSlice } from '@/processes/MinerAccounts';
import { YEAR_IN_MS } from '@/shared/constants/date';
import { createWrapper } from 'next-redux-wrapper';
import { nextReduxCookieMiddleware, wrapMakeStore } from "next-redux-cookie-wrapper";
import { api } from './api';

const makeStore = () => {
  return configureStore({
    reducer: combineReducers({ 
      confirmation: ConfirmationSlice,
      settings: SettingsSlice,
      minerAccounts: MinerAccountsSlice,
      [api.reducerPath]: api.reducer,
    }),
    devTools: true,
    // @ts-ignore
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      serializableCheck: false,
    }).prepend(
      nextReduxCookieMiddleware({
        expires: new Date(new Date().getTime() + YEAR_IN_MS),
        subtrees: [
          {
            subtree: `minerAccounts.recentSearches`,
            cookieName: "cookie:persist:recentSearches",
          },
          {
            subtree: `settings`,
            cookieName: "cookie:persist:settings",
          },
        ],
      })
    ).concat(api.middleware),
  })
};

export const wrapper = createWrapper(wrapMakeStore(makeStore));
