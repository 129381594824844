export const meta = {
  "BTC": {
    title: "meta.btс.title",
    desc: "meta.btc.desc",
    preview: 'https://lbd.hiveos.farm/kb/images/stable_BTC_mining_pool.png',
  },
  "ETC": {
    title: "meta.title",
    desc: "meta.desc",
    preview: 'https://lbd.hiveos.farm/pool/best_Ethereum_Classic_pool.png',
  },
  "RVN": {
    title: "meta.title",
    desc: "meta.desc",
    preview: 'https://lbd.hiveos.farm/pool/best_Ravencoin_pool.png',
  },
  "KAS": {
    title: "meta.kas.title",
    desc: "meta.kas.desc",
    preview: 'https://lbd.hiveos.farm/kb/images/high_paying_KASPA_mining_pool.png',
  },
  "DNX": {
    title: "meta.dnx.title",
    desc: "meta.dnx.desc",
    preview: 'https://lbd.hiveos.farm/kb/images/dynex_banner.png',
  },
  "GRAM": {
    title: "meta.gram.title",
    desc: "meta.gram.desc",
    preview: 'https://lbd.hiveos.farm/kb/images/gram_mining_pool.png',
  },
  "404": {
    title: "meta.404.title",
    desc: "meta.404.desc",
    preview: null,
  },
  "500": {
    title: "meta.500.title",
    desc: "meta.500.desc",
    preview: null,
  },
}