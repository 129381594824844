import { useSelector } from 'react-redux';
import { useGetPoolStatsQuery } from './api';
import { selectPollingInterval } from '@/processes/Settings';

const PoolStats = () => {
  const pollingInterval = useSelector(selectPollingInterval);
  useGetPoolStatsQuery(null, { pollingInterval });
  return null;
}

export default PoolStats;
