import _toLower from 'lodash/toLower'
import _get from 'lodash/get'

export const CLEAN_RULES = {
  ETC: {
    format: (account: '') => {
      return _toLower(account).replace("0x", '');
    }
  }
}

export const cleanAccount = (account: any = '', coin: string) => {
  if (!account) { return ''; }

  let cleanAccount = account;
  
  const formatter = _get(CLEAN_RULES, [coin, 'format'], null);
  if (typeof formatter === 'function') {
    cleanAccount = formatter(cleanAccount);
  }

  return cleanAccount;
}
