import jwt_decode from "jwt-decode";


export function parseJwtToken(token: string): any {
  try {
    const decoded = jwt_decode(token);
    return decoded;
  } catch (e) {
    return {};
  }
}
