const IconSortingArrowDown = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6"
      height="3"
      fill="none"
      viewBox="0 0 6 3"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M5.554.146a.5.5 0 010 .708L3.907 2.5a1 1 0 01-1.414 0L.847.854a.5.5 0 01.707-.708L3.2 1.793 4.847.146a.5.5 0 01.707 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default IconSortingArrowDown
