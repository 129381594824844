const IconFilter = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill="none"
      viewBox="0 0 28 28"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7.25 10a.5.5 0 01.5-.5h12.5a.5.5 0 010 1H7.75a.5.5 0 01-.5-.5zm2.5 4a.5.5 0 01.5-.5h8a.5.5 0 010 1h-8a.5.5 0 01-.5-.5zm2 4a.5.5 0 01.5-.5h4a.5.5 0 010 1h-4a.5.5 0 01-.5-.5z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default IconFilter
