const IconDownload = (props: any) => {
  return (
    <svg
      width={15}
      height={14}
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.333.333a.5.5 0 01.5.5v7.793l3.146-3.147a.5.5 0 11.707.708l-3.953 3.952a.567.567 0 01-.801 0L2.979 6.187a.5.5 0 01.707-.708l3.147 3.147V.833a.5.5 0 01.5-.5zm-7.167 12.5a.5.5 0 01.5-.5h13.333a.5.5 0 110 1H.666a.5.5 0 01-.5-.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default IconDownload;
